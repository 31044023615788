* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Manrope', 'Noto Kufi Arabic', 'Segoe UI', sans-serif;
  background-color: var(--page-background);
}

:root {
  --page-background: #F5F5F5;
  --primary-color: #450503;
  --secondary-color: #F0CB0B;
  --third-color: #EBF0C1;
  --danger-color: #E53935;
  --success-color: #4BB543;
  --lighter-background: #FF7043;
  --warning-color: #ffcc00;
  --pending-color: #F29339;
  --link-color: #0000EE;
  --max-login-width: 1100px;
  --max-page-width: 1200px;
  --max-admin-page-width: 1100px;
}

.singleline{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
